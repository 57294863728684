import styled from 'styled-components';

export const Container = styled.div `
    width: 100%;    
    height: 100%;
    position: absolute;
    top: 2500px;
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;
    z-index: 0;

    
    @media only screen and (max-device-width: 480px){
        top: 2700px;
        height: 100%;
    }

    @media screen and (min-width:481px) and (max-width: 1366px){
        top: 2100px;
        height: 1000px;
    }
`
export const ContainerTop = styled.div `
   position: absolute;
   top: 100px;
    width: 25%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    right: 15%;
    h1 {
        font-size: 22px;
        align-self: flex-start;
        font-family: 'Open Sans', sans-serif;
    }
    h1:first-child{
        border-bottom: solid 3px rgb(251,111,0);
        width: 100%;
        padding-bottom: 10px
    }
    h3 {
        font-size: 19px;
        align-self: flex-start;
        font-family: 'Open Sans', sans-serif;
        margin: 10px;
    }

    h4 {
        font-size: 20px;
        align-self: flex-start;
        font-family: 'Roboto', sans-serif;
    }

    .mobile {
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .mobile a {
        display: flex;
        flex-direction: column;
        padding: 20px;
        background: transparent;
        font-size: 20px;
        align-items: flex-start;
        margin: 0%;
    }

    
    i {
        align-self: flex-start;
        color: black;
        transition: 0.3s;
        float: left;
    }
    i:hover{
        opacity: 0.7;
        transition: 0.3s;
    }
                        

    @media (max-width: 768px) {
        width: 95%;
        right: 2.5%; 
    } 

    @media screen and (max-width: 1366px){
        h3 {
            font-size: 15px;
        }
    }


                  
`
export const ContainerBottom = styled.div `
    width: 25%;
    display: flex;
    flex-direction:column;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    top: 47%;
    right: 15%;
    margin-bottom: 80px;

    form {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        h1 {
        width: 100%;
        font-size: 24px;
        font-family: 'Roboto', sans-serif;
     
    }
    h1:first-child{
        border-bottom: solid 3px rgb(251,111,0);
        width: 100%;
        padding-bottom: 10px
    }
    }
    

    @media only screen and (max-device-width: 480px) {
        width: 95%;
        right: 2.5%; 
        padding-bottom: 80px;
    }  

`

export const ContainerLeft = styled.div ` 
    background: white;
    width: 40%;
    height: 60%;
    bottom: 20%;
    position: absolute;
    left: 10%;
    display: flex;
    flex-direction:column;
    align-items: center;
    border: none;
    justify-content: flex-start;

    h1{
        font-size: 20px;
        font-family: 'Roboto', sans-serif;
    }

    .topDiv {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;

    }
    .topDiv h2 {
        font-family: 'Ralaway', sans-serif;
        font-size: 24px; 
        margin: 0;
    }
    .topDiv h2:first-child {
        border-bottom: solid 3px rgb(251,111,0);
        width: 50%;
        
        padding-bottom: 10px
    }
    p{
        font-family: 'Roboto', sans-serif;
        font-size: 13px; 
        text-align: justify;
        align-self: center;
        margin: 0;
        
    }
    iframe{
        padding-top: 20px;
        width: 80%;
        height: 80%;
        border: none;
    }


    @media (max-width: 768px){
        display: none;
    }
`


