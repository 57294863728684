import React, { Component } from 'react';
import * as S from './styles';
import axios from 'axios';
import swal from 'sweetalert2'


class Contact extends Component {
    constructor() {
        super();
        this.state = {
          name: "",
          email: "",
          message: "",
          status: "ENVIAR"
        };   
      }
  
      handleSubmit(event) {
        event.preventDefault();  
        this.setState({ status: "Enviando" });  
        axios({
          method: "POST",
          url: "https://createsoftware.herokuapp.com/send",
          data: this.state,
        }).then((response) => {
          if (response.data.status === "Enviado") {
            swal.fire({
              title: "Maravilha!",
              text: "Já recebemos sua mensagem, em breve vamos entrar em contato.",
              icon: "success"
              
          })
            this.setState({ name: "", email: "", message: "", status: "Enviar" });
          } else if (response.data.status === "failed") {
            swal.fire({
              title: "Oops!",
              text: "Infelizmente não foi possível enviar a sua mensagem.",
              icon: "error"
          })
          }
        });
      }
    
      handleChange(event) {
        const field = event.target.id;
        if (field === "name") {
          this.setState({ name: event.target.value });
        } else if (field === "email") {
          this.setState({ email: event.target.value });
        } else if (field === "message") {
          this.setState({ message: event.target.value });
        }
      }
   
    render() 
    {
        let buttonText = this.state.status;
        return (
            <S.Container id="section3">
                <S.ContainerLeft>
                  <div className="topDiv">
                    <h2>Onde estamos?</h2>
                  </div>
                  <p>Rua Marechal Floriano, 158, Centro.</p>
                  <p>São Bento do Sul-SC</p>
                  <p>89280-343</p>
                  <p>+55 (47) 3305-6741</p>
                 <iframe title="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3578.33873713283!2d-49.38400928500251!3d-26.250667483417136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ddd4c09f7e3479%3A0xef258509e1cef20d!2sRua%20Marechal%20Floriano%2C%20158%20-%20Centro%2C%20S%C3%A3o%20Bento%20do%20Sul%20-%20SC%2C%2089280-342!5e0!3m2!1spt-BR!2sbr!4v1614943427467!5m2!1spt-BR!2sbr" ></iframe>
                
                
                </S.ContainerLeft>
                <S.ContainerTop>
                        <h1>Solicite uma demonstração</h1>
                        <h3>E-mail: comercial@createsoftware.com.br</h3>
                        <h3>Telefone: +55 (47) 3305-6741</h3>
                        <h3>Siga-nos nas redes sociais:</h3> 
                        

                        <div className="mobile">
                            <a href="https://www.instagram.com/createsoftwarebrasil" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i></a>
                            <a href="https://www.facebook.com/createsoftwarebrasil" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-f"></i></a>
                            <a href="https://Linkedin.com/company/create-software" target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin-in"></i></a>
                            <a href="https://www.youtube.com/channel/UCotiqjBIKOYk2otP7Q2tnbg" target="_blank" rel="noopener noreferrer"><i class="fa fa-youtube-play"></i></a>
                        </div>
                </S.ContainerTop> 
                <S.ContainerBottom>
                    <form onSubmit={this.handleSubmit.bind(this)} method="POST">
                    <h1>Entre em contato</h1>
                    
                    <div class="input-field col s12">
                            <input id="name" type="text" 
                            value={this.state.name}
                            onChange={this.handleChange.bind(this)}
                            required/>
                            <label htmlfor="name">Nome:</label>
                    </div>
          
                    <div class="input-field col s12">
                            <input id="email" type="email" class="form-input"
                            value={this.state.email}
                            onChange={this.handleChange.bind(this)}
                            required/>
                            <label htmlfor="email">E-mail:</label>
                        </div>
                  
                    <div class="input-field col s12">
                          <textarea class="materialize-textarea"
                          id="message" 
                          value={this.state.message}
                          onChange={this.handleChange.bind(this)}
                          required/>
                          <label htmlfor="message">Mensagem:</label>
                    </div>
                    <button class="btn waves-effect waves-light" type="submit" name="action">
                      {buttonText}                        
                    </button>

                    
                   
                  
                </form>  
                    
                </S.ContainerBottom>
            </S.Container>
            )
        } 
    }
    
    export default Contact
    