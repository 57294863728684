import React from 'react';
import './App.css';

import Section1 from './components/section1/section1.component';
import Section2 from './components/section2/section2.component';
import Section3 from './components/section3/section3.component';
import Section4 from './components/section4/section4.component';
import Footer from './components/Footer/Footer'
import Nav from './components/Nav/Navbar'


function App() {
  return (
    <div className="App">
      <Nav/>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
    </div>
  );
}

export default App;
