import React from 'react';
import * as S from './styles'
import {Link} from 'react-scroll'
import Servicos from '../../Assets/servicos.png'


export default function Section4() {
  return (
    <S.Container id="section4">
      <S.TopSide> 
         <h1>Software House</h1>
      </S.TopSide>
      <S.Geral>      
      <S.LeftSide>
        <div className="escrita">
            <h2>O que fazemos?</h2>
                <p class="escrita">Além dos nossos produtos já consolidados, desenvolvemos sistemas de acordo com a sua necessidade. Adotamos o conceito de Software House tendo em mente que cada cliente tem sua particularidade, e queremos
                  trazer a solução que seja ideal para o seu negócio. Independente do tamanho, necessidade ou a área de atuação da sua empresa acreditamos que podemos desenvolver aquilo que você precisa.
                </p>

                
                <button class="btn waves-effect waves-light" to="section3" spy={true} smooth={true} duration={1000}>
                      <Link to="section3" spy={true} smooth={true} duration={1000}>
                        CONTATO
                    </Link>
              </button>
        </div>
             
        </S.LeftSide>
        <S.RightSide>
          <div className="listitem">
              <li>Desenvolvimento sob demanda
                <p>Solicite desenvolvimento de um sistema do seu jeito. Informe os requisitos e necessidades e desenvolveremos uma ferramenta para auxiliar na gestão do seu negócio. </p>
              </li>
              <li>Terceirização de desenvolvimento
              <p>Terceirize desenvolvimento da sua equipe, desafogue as grandes demandas de projetos com um serviço terceirizado.</p>
              </li>
              
              <li>Automação de processos
              <p>Otimize tempo e recursos automatizando processos internos da sua empresa.</p>
              </li>
          </div>
          </S.RightSide>
        </S.Geral>
    </S.Container>
  );
}
