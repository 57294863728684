import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';





const Container = styled.div ` 
  width: 100%;
  height: 100px;
  z-index: 1000;
  position: fixed;
  top: 0;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  background: rgba(255,255,255,0.8);;
  @media (max-width: 768px){
    background: transparent ;
  }
`
const Right = styled.div ` 
  width: 100%;
  position: absolute;
  justify-content: center;
  display: flex;
  flex-direction: row;

 

`


const Navbar = () => {
  return (
    <Container>
        <Right>
          <Burger/>
        </Right>
    </Container>
  )
}

export default Navbar
