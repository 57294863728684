import React from 'react';
import styled from 'styled-components';
import Logo from '../../Assets/logo_create.png'
import LogoBranca from '../../Assets/logo_branca.png'
import { Link } from 'react-scroll';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  width: 100%;

  .logo_branca{
    display: none;
  }
  .logo {
    padding:0;

    img {
      width: 150px;
      margin-right: 100px;
    }
  }
  a {
    margin-left: 20px;
    padding: 18px 10px;
    font-size: 20px;
    color: rgb(35,53,70);
    letter-spacing: 1.5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    transition: 0.2s;
   
    :hover {
      cursor: pointer;
      opacity: 0.3;
      transition: 0.2s;

    }

    .active {
      opacity: 0.8;
    }

    p{
    margin: 0;
    padding-left: 100px;
    font-size: 16px;
    text-shadow: none;
    i {
        padding-right: 15px;
        font-size: 18px;
      }
  } 
  }
  

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: rgba(0,0,0,0.9);
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width:100%;
    transition: transform 0.3s ease-in-out;
    margin: 0;    
    justify-content: start;
    padding-top:80px;
    a {
      color: #fff;
      p{
      color: #fff;
        padding-left: 0;
        font-size: 14px;
        font-weight: 500;
      i {
        padding-right: 15px;
        font-size: 14px;
      }
    }
  }
  .logo {
    display: none;
  }
  .logo_branca {
    display: inline;
    padding:0;
    margin-bottom: 40px;
    
    img {
      width: 100px;
      margin-right: 0px;
      padding-left: 20px;
    }
  }
    
    }
   
`;

const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
          <div className="logo">
              <img src={Logo} alt="logo"></img>
          </div>
          <div className="logo_branca">
              <img src={LogoBranca} alt="logo"></img>
          </div>
          <Link class="active" to="section1" spy={true} smooth={true} duration={1000}>
            Home
         </Link>
          <Link  to="section2" spy={true} smooth={true} duration={1000}>
            Produtos
          </Link>
          <Link  to="section4" spy={true} smooth={true} duration={1000}>
            Serviços
          </Link>
          <Link to="section3" spy={true} smooth={true} duration={1000}>
            Contato
          </Link>
          <a href="http://api.whatsapp.com/send?1=pt_BR&phone=554733056741" target="_blank" rel="noopener noreferrer"> 
            <p><i class="fab fa-whatsapp"></i>(47) 3305-6741</p>
          </a>
    </Ul>
  )
}

export default RightNav
