import React, { Component } from 'react';
import './styles.css'
class Footer extends Component {
    render() {
        return (
            <div class="main">
                     <div class="footer-basic">
                        <footer>
                            <div class="social">
                                
                                <a href="https://www.instagram.com/createsoftwarebrasil" target="_blank" rel="noopener noreferrer"><i class="icon ion-social-instagram"></i></a>
                                <a href="https://www.youtube.com/channel/UCotiqjBIKOYk2otP7Q2tnbg" target="_blank" rel="noopener noreferrer"><i class="icon ion-social-youtube"></i></a>
                                <a href="https://Linkedin.com/company/create-software" target="_blank" rel="noopener noreferrer"><i class="icon ion-social-linkedin"></i></a>
                                <a href="https://www.facebook.com/createsoftwarebrasil" target="_blank" rel="noopener noreferrer"><i class="icon ion-social-facebook"></i></a>
                                <p class="copyright">Create Software © 2021</p>
                            </div>                            
                            
                        </footer>
                    </div>
                    
            </div>
       
            )
        }
    }
    
export default Footer
    