import styled from 'styled-components'


export const Container = styled.div ` 
   
    position: relative; 
    background: #f4f4f4;
    top: 1000px;
    display: flex;
    padding-top: 200px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 600px;

    @media only screen and (max-device-width: 480px) {
    flex-direction: column;
    height: 1000px;
    top: 800px;
    padding-top: 100px;

  }

  @media screen and (min-width:481px) and (max-width: 1366px){
    top: 600px;
    padding-top: 0px;
    height: 700px;
  }
`

export const TopSide = styled.div  `
    width: 100%;
    position: absolute;
    top: 0;
    justify-content: center;
    display: flex; 


    h1 {
  
      font-weight: 500;
      font-size: 32px;
      margin: 0;
      padding-top: 100px;
      padding-bottom: 5px;
      
    }
      h1:first-child{
              border-bottom: solid 1.5px rgb(251,111,0);
              width: 50%;
          }

    @media screen and (max-width: 1366px) {
      h1 {
        padding-top: 100px;
        font-size: 26px;
      }

      
    }

    @media (max-width: 768px) {
      width: 90%;
      h1 {
        font-size: 22px;
        padding-top: 100px;

    }

    h1:first-child{
        width: 100%;
    }
    }
    
`
export const Geral = styled.div  `
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3.56rem;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }

  @media screen and (max-width: 1366px){
    margin-top: 0;
  }
`
export const LeftSide = styled.div  `
    width: 50%;
    display: flex; 
    justify-content: center;
    align-items: flex-start;

    

  .escrita {
    width: 80%;
    display: flex;
    flex-direction:column;
    align-items: flex-start;

    h2 {
    padding-top: 10px;
    font-weight: 400;
    font-size: 22px;
    font-family: 'Raleway', sans-serif;
    text-align: start;
    letter-spacing: .8px;
    }

  h2:first-child{
        border-bottom: solid 2px rgb(251,111,0);
        width: 70%;
        padding-bottom: 10px
    }
    
    
    p {
    color: black;
    font-size: 18px;
    font-weight: 300;
    word-spacing: 1px;
    text-align: justify;
    font-family: 'Lato',sans-serif;
    border: none;
    margin-right: 10px;
    }
    a {
      text-decoration: none;
      color: #fff;
      background-color: #26a69a;
      text-align: center;
      letter-spacing: .5px;
    }
  }

  @media screen and (max-width: 1366px){
    .escrita {
      h2 {
      font-size: 18px;
    }
    p {
      font-size: 15px;
    }
    }
    
  }
 

  @media (max-width: 768px) {
      display: flex;
      width: 100%;
      top: 30px;
      height: 300px;

  .escrita {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
   
  
  h2 {
    text-align: start;
    font-weight: 400;
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
    line-height: 110%;
    margin: 1.78rem 0 1.424rem 0;
  }

  p {
      font-size: 14px;
      margin: 0;
  }
  }
   
  

  }
`

export const RightSide = styled.div ` 
    width: 50%;     
    display: flex; 
    justify-content: center;
    align-items: center;

    img {
      width: 90%;
    }

    .listitem {

      padding-top: 10px;
      margin-top: 1.78rem;
      width: 100%;
      height: 450px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;

    }

    .listitem li {
        margin-left: 20px;
        font-size: 18px;
        color: rgb(33,63,77);
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        width: 80%;
        
      }

    .listitem li p {
      font-size: 15px;
      text-align: justify;
      margin: 18px;
      color: black;
      font-family: 'Open Sans', sans-serif;
      text-transform: none;
      font-weight: 400;
    }

    
    @media (max-width: 768px) {
      
      height: 450px;
      width: 100%;
      top: 320px;

      .listitem {
        height: 450px;
        justify-content:center;
        margin:0;
        align-items:center;
      }
      .listitem li {
        font-size: 16px;width: 95%;
      }
    }

    
`