import React from 'react';
import * as S from './styles'
import Analytics from '../../Assets/analytics.png'
import Totem from '../../Assets/TotemSite.png'



export default function Section2() {

  return (
    <S.Section2Styled id="section2">
      <h1>Conheça nossos produtos<br/></h1>
        <S.LeftSide>
                 <div class="escrita">
                <p>
                  <h2>SIMPLE ANALYTICS</h2>O Simple Analytics traz como principal função a agilidade e praticidade de analisar situações 
                    reais de cada negócio. Com consultas em tempo real, diretamente no banco de dados, você terá maior 
                    desempenho para obter as resposas no momento que você precisa. São dezenas de análises, como visão
                      financeira, estoque, rupturas, vendas, auditorias de PDV, além disso, você pode ainda compartilhar
                      gráficos e resultados com quem você quiser do seu grupo de trabalho através do Simple Feed, onde 
                      você terá acesso também aos Insights recomendados, que auxiliam em situações críticas que precisam 
                      ser analisadas. 
                      <br/><br/>
                      Saiba mais sobre o Simple Analytics clicando <a href="http://www.createsoftware.com.br/material/analytics.pdf" target="_blank" rel="noopener noreferrer">aqui.</a>
                  </p>
                  
                </div>
                <div class="image">
                   <img src={Analytics} alt="produto"></img>
                </div>
        </S.LeftSide>    
        <S.RightSide>
              <div class="image">
                <img src={Totem} alt="produto"></img>
              </div>
                <div class="escrita">                
                  <p>
                  <h2>SIMPLE TOTEM</h2>O Simple Totem traz o conceito de gerenciamento de relacionamento com o cliente, 
                  com diversas inovações como raspadinha digital, interface gamificada, adega virtual e gestão de promoções. 
                  Você terá uma solução pronta para seus clientes e colaboradores. Além disso, você pode criar promoções que 
                  podem ser totalmente integradas com o Totem, dando ao cliente maior controle de seus cupons promocionais, 
                  por exemplo. Na área do cliente, ele poderá dar sugestões, e opiniões sobre a sua loja, e também poderá
                   realizar uma avaliação do atendimento que ele obteve naquele dia.
                   <br/><br/>
                  Saiba mais sobre o Simple totem clicando <a href="http://www.createsoftware.com.br/material/simple.pdf" target="_blank" rel="noopener noreferrer">aqui.</a>
                  </p>
                </div>
                <div class="imageBottom">
                <img src={Totem} alt="produto"></img>
                </div>
                
        </S.RightSide>
    </S.Section2Styled>
  );
}
