import React from 'react';
import styled from 'styled-components';
import Logo from '../../Assets/logo_create.png'
import Wrapper from '../wrapper/wrapper.component';
import ReactTypingEffect from 'react-typing-effect';

const Container = styled.div`
   width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex-direction: column;
    background-image: url("https://media3.s-nbcnews.com/i/newscms/2019_05/2732186/190128-women-workplace-stock-cs-120p_8ebb426632d2092925fb130dcdb8fbd5.jpg");
    background-color: rgba(255,255,255,0.4);
    background-blend-mode: lighten;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    z-index:50;
 
  .active {
    border-bottom: 1px solid white;
  }

  img {
      opacity: 1;
      width: 400px;
      animation: fadeInAnimation ease 3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

  .typingeffect {
      margin-top: 20px;
      font-size: 28px;
      font-weight: 600;
      font-family: 'Open Sans', sans-serif;
      animation: fadeInAnimation ease 6s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    @media (max-width: 768px) {
      img {
        width: 300px;
      }
    }
`;

export default function Section1() {
  return (
    <Container id="section1">
      <Wrapper>
    

      <img src={Logo} alt="Logo"></img>
            <ReactTypingEffect className="typingeffect" text={['You Need','We Create.']} speed={100} eraseDelay={200} typingDelay={100}/>
   
      </Wrapper>
    </Container>
    
  );
}
