import styled from 'styled-components'

export const Section2Styled = styled.div`


background-color:white;
display: flex; 
position: relative;
top: 950px;
width: 100%;
justify-content: center;
flex-direction: column;
align-items: center;
padding-top: 100px;

h1 {
  
  font-weight: 500;
  font-size: 32px;
  margin: 0;
  padding-bottom: 5px;
  
}
h1:first-child{
        border-bottom: solid 1.5px rgb(251,111,0);
        width: 50%;
    }


    @media only screen and (max-device-width: 480px) {
      padding-top: 100px;
      top: 800px;
      height: 100%;
    h1 {
        font-size: 22px;
    }

    h1:first-child{
        width: 90%;
    }
    }

@media screen and (min-width:481px) and (max-width: 1366px){
    top: 650px;
    padding-top: 100px;
    height: 900px;

    h1 {
      font-size: 26px;
    }
}
`

//LADO ESQUERDO E TOP
export const LeftSide  = styled.div `
  display: flex;
  flex-direction: row;
  width: 80%;
  left: 0;
  justify-content: center;
  align-items: center;
  .escrita {
    width: 100%;
  }
  .image {
    width: 100%;
    img {
    height: 300px;
  }
  }
  

  h2 {
    padding-top: 10px;
    font-weight: 400;
    font-size: 22px;
    font-family: 'Raleway', sans-serif;
    letter-spacing: .8px;
    
  }

  h2:first-child{
        border-bottom: solid 2px rgb(251,111,0);
        width: 50%;
        padding-bottom: 10px
    }



  p {
    color: black;
    font-size: 18px;
    font-weight: 300;
    word-spacing: 1px;
    text-align: justify;
    font-family: 'Lato', sans-serif;
    border: none;
    margin-right: 10px;

    a {
    text-decoration: none;
    color: black;
    font-weight: 400;


    :hover {
      opacity: 0.7;
    }
  }
  }

  @media screen and (max-width:1366px){
    width: 70%;

    h2 {
      font-size: 18px;
    }
    p {
      font-size: 15px;
    }

    .image {
    width: 100%;
    img {
    height: 240px;
  }
  }
}

@media only screen and (max-device-width: 480px) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;

  .escrita {
    width: 95%;
    
  }

  .image {
    width: 95%;
    

    img {
        width: 100%;
        height: 200px;
    }
  }
  h2 {
    font-weight: 400;
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
  }
   
  p {
      font-size: 14px;
  }

  }
`
//LADO DIREITO E BOTTOM
export const RightSide = styled.div `
  display: flex;
  flex-direction: row;
  width: 80%;
  right: 0;
  justify-content: center;
  align-items: center;

  .escrita {
    width: 50%;
  }
  .image {
    width: 50%;
  }
  img {
    height: 300px;
  }
 

  .imageBottom {
      display: none;
  }

  h2 {
    padding-top: 10px;
    font-weight: 400;
    font-size: 22px;
    font-family: 'Raleway', sans-serif;
    letter-spacing: .8px;
  }

  h2:first-child{
        border-bottom: solid 2px rgb(251,111,0);
        width: 50%;
        padding-bottom: 10px
    }

  p {
    color: black;
    font-size: 18px;
    font-weight: 300;
    word-spacing: 1px;
    text-align: justify;
    font-family: 'Lato', sans-serif;
    border: none;
    margin-right: 10px;
  
  a {
    text-decoration: none;
    color: black;
    font-weight: 400;


    :hover {
      opacity: 0.7;
    }
  }
  }

  @media screen and (max-width:1366px){
    width: 70%;

    h2 {
      font-size: 18px;
    }
    p {
      font-size: 15px;
    }
    .escrita {
      width: 100%;
      
    }
    .image {
      
    width: 100%;
    img {
    height: 240px;
  }
  }
}

@media only screen and (max-device-width: 480px) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;

  .escrita {
    width: 95%;
    
  }

  .image {
    display: none;
    
  }
  .imageBottom {
      display: flex;
      width: 95%;
  
    img {
        width: 100%;
        height: 200px;
    }
  }
  

  h2 {
    font-weight: 400;
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
  }
   
  p {
      font-size: 14px;
  }

    }
  
`;